

































































































.kiwi-aboutnew {
    position: absolute;
    bottom: 0;
    height: 53px;
    left: 0;
    right: 0;
    padding: 0 10px 10px 10px;
    box-sizing: border-box;
}

.kiwi-aboutnew-sep {
    margin-bottom: 10px;
    border-top: 1px solid #ddd;
}

.kiwi-aboutnew-help {
    font-size: 1.9em;
    float: right;
    cursor: pointer;
}

.kiwi-aboutnew-content {
    display: none;
    position: absolute;
    top: 4px;
    left: 0;
    bottom: 53px;
    width: 100%;
    background: #fff;
    text-align: left;
    line-height: 1.6em;
    padding: 2em;
    box-sizing: border-box;
    overflow: auto;
}

.kiwi-aboutnew-content--open {
    display: block;
}

@media screen and (min-width: 700px) {
    .kiwi-aboutnew-content {
        padding: 2em 3em;
    }
}

.kiwi-personal {
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    text-align: center;
    padding-top: 1em;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.kiwi-personal h1 {
    margin: 0 0 20px 0;
}

.kiwi-personal p {
    margin: 0 0 10px 0;
}

.kiwi-personal button {
    margin: 10px auto 0 auto;
    padding: 0 40px;
    font-size: 1em;
    line-height: 40px;
}

/* Only show the toggle state browser link if on a small screen */
.kiwi-personal-existing-networks {
    display: none;
}

@media screen and (max-width: 500px) {
    .kiwi-personal-existing-networks {
        display: inherit;
    }
}

