




































































































































.kiwi-selfuser-nick,
.kiwi-selfuser-host,
.kiwi-selfuser-status {
    display: inline-block;
    padding: 0 10px;
    cursor: default;
    width: 100%;
    box-sizing: border-box;
}

.kiwi-selfuser-nick {
    min-width: 85px;
    font-weight: bold;
    padding: 5px 10px 0 10px;
}

.kiwi-selfuser-modes {
    font-weight: normal;
    opacity: 0.8;
    font-size: 0.8em;
}

.kiwi-selfuser-host {
    font-style: italic;
    opacity: 0.8;
    padding-left: 26px;
    font-size: 0.8em;
    word-break: break-all;
}

.kiwi-controlinput-selfuser .kiwi-close-icon {
    line-height: 36px;
    border-radius: 0;
}

/* Style the icons in the SelfUser */

.kiwi-selfuser-nick i {
    font-weight: 400;
    float: right;
    opacity: 0.6;
    cursor: pointer;
    margin-top: 3px;
    transition: all 0.3s;
}

.kiwi-selfuser-nick i:hover {
    opacity: 1;
    transition: all 0.2s;
}

.kiwi-selfuser-nick i:last-of-type {
    margin-right: 15px;
}

.u-form.kiwi-away-checkbox-form {
    padding: 0 0 5px 24px;
}

.u-form .kiwi-selfuser-away-label {
    margin: 0 0 2px 0;
}

.u-form .kiwi-selfuser-away-label span {
    margin-right: 5px;
}

.kiwi-selfuser-error-message {
    width: 100%;
    display: block;
    padding: 0.5em 10px;
    box-sizing: border-box;
    margin: 5px 0 5px 0;
    text-align: center;
    border-radius: 6px;
}

.kiwi-selfuser-actions {
    padding: 5px 10px;
    margin-bottom: 10px;
}

.kiwi-selfuser-actions form {
    position: relative;
}

.kiwi-selfuser-actions form .u-input-prompt-label {
    display: block;
    width: 100%;
}

.kiwi-selfuser-actions .u-input-button-container {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 1;
}

