































































.kiwi-avatar > span {
    text-transform: uppercase;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-weight: 600;
    margin-top: 3px;
    border: 2px solid;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.kiwi-avatar--image > span {
    border: none;

    /* box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5); */
}

