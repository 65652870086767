


























































.u-input-prompt-inputs {
    display: flex;
}

.u-input-prompt-inputs > input {
    flex: 1;
    margin-right: 1em;
}

.u-input-prompt-inputs > a {
    margin-right: 0.5em;
}
