.kiwi-appsettings {
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  padding: 8px 0 0 0;
  margin-top: -7px;
}
.kiwi-appsettings .u-form {
  width: 100%;
  overflow: hidden;
}
.u-form label.kiwi-appsettings-setting-language.kiwi-appsettings-setting-language {
  display: flex;
  margin-bottom: 2em;
}
.kiwi-appsettings-setting-language select {
  flex-grow: 0;
  max-width: 200px;
}
.kiwi-appsettings-setting-language div {
  flex-grow: 1;
  text-align: right;
  margin-right: 1em;
}
.kiwi-appsettings-setting-theme span {
  margin-left: 0;
}
.kiwi-appsettings-setting-theme select {
  float: right;
}
.kiwi-appsettings-setting-showjoinpart span {
  max-width: none;
}
.kiwi-appsettings-full span,
.kiwi-appsettings-full input {
  width: 100%;
}
.kiwi-appsettings-aliases {
  padding: 1em 20px 2em 20px;
}
.kiwi-appsettings .kiwi-appsettings-setting-scrollback input {
  margin-right: 10px;
  width: 80px;
}
.kiwi-appsettings-tab-container {
  width: 100%;
}
.kiwi-appsettings-close {
  float: right;
}
.kiwi-appsettings .u-form label {
  display: block;
}
.kiwi-appsettings .u-form label span {
  text-align: left;
  margin-left: 0;
}
.kiwi-appsettings-theme-reload {
  margin-left: 1em;
  float: right;
  cursor: pointer;
}
.kiwi-settings-aliases-input {
  height: auto;
  min-height: 300px;
  min-height: 400px;
  line-height: normal;
  width: 100%;
  max-width: 100%;
  resize: vertical;
}
.kiwi-appsettings-block {
  max-width: 400px;
  width: auto;
  display: block;
  box-sizing: border-box;
  margin: 20px auto 20px auto;
}
.kiwi-appsettings-block.kiwi-appsettings-block-advanced {
  max-width: inherit;
  margin: 20px;
}
.kiwi-appsettings-block h3 {
  width: 100%;
  line-height: 45px;
  padding: 0 10px;
  box-sizing: border-box;
}
.kiwi-appsettings-section {
  padding: 10px;
}
.kiwi-appsettings-block-aliases {
  max-width: 750px;
}
.kiwi-appsettings-title {
  display: block;
  cursor: pointer;
  padding: 0 10px;
  margin: -1px 0 0 0;
  font-weight: 600;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  text-transform: uppercase;
  line-height: 47px;
  text-align: right;
  transition: background 0.3s;
}
.kiwi-appsettings-title h2 {
  padding: 10px 0 11px 20px;
  width: auto;
  float: left;
}
.kiwi-appsettings-title a {
  float: right;
  position: static;
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 1.4em;
}
.kiwi-appsettings-title i {
  margin-left: 10px;
  font-size: 1.5em;
  float: right;
  line-height: 47px;
}
.kiwi-appsettings-messagelistDisplay select {
  float: right;
}
@media screen and (max-width: 769px) {
  .kiwi-appsettings {
    width: 100%;
    z-index: 999;
    position: fixed;
    left: 0;
    transition: left 0.5s;
  }
  .kiwi-appsettings .kiwi-appsettings-block {
    width: 90%;
  }
  .kiwi-appsettings .u-form label span {
    width: auto;
    margin-right: 0;
    display: inline-block;
  }
}
