






















































.u-input-confirm {
    display: inline-block;
    padding: 10px;
}
