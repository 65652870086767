













































































































































































.kiwi-channellist {
    box-sizing: border-box;
    text-align: center;
    transition: all 0.6s;
}

.kiwi-channellist-padding-top {
    padding-top: calc(45vh - 80px);
}

.kiwi-channellist-padding-top .kiwi-channellist-nav {
    width: 100%;
    text-align: center;
}

.kiwi-channellist-nav {
    padding: 10px 20px;
    box-sizing: border-box;
}

/* Input form styling */
.kiwi-channellist-nav .u-form {
    display: flex;
    justify-content: center;
}

.kiwi-channellist-nav .u-form .u-input {
    width: 324px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.kiwi-channellist-nav .u-form .u-button-primary,
.kiwi-channellist-nav .u-form .u-button-secondary {
    font-size: 1.3em;
    border-radius: 0 5px 5px 0;
    border: none;
    display: flex;
    align-items: center;
}

.kiwi-channellist-nav .u-form .u-button-primary i,
.kiwi-channellist-nav .u-form .u-button-secondary i {
    margin-left: -2px;
}

.kiwi-channellist-pagination {
    display: inline-block;
    margin: 20px auto 10px auto;
    font-size: 1.2em;
}

.kiwi-channellist-pagination a {
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
}

.kiwi-channellist-search {
    display: inline-block;
}

.kiwi-channellist-info {
    text-align: center;
}

/* Table Styling */
.kiwi-channellist-table {
    width: 100%;
}

.kiwi-channellist table {
    border: none;
    border-collapse: collapse;
}

.kiwi-channellist table thead th {
    font-size: 1.1em;
    cursor: default;
    text-align: left;
    padding: 10px 1em 5px 1em;
}

.kiwi-channellist table tbody td {
    padding: 2px 1em;
    text-align: left;
}

.kiwi-channellist table .kiwi-channellist-user-center {
    text-align: center;
}

.kiwi-channellist tr td:first-child {
    white-space: nowrap;
}

.kiwi-channellist-table-topic {
    word-break: break-word;
}

.kiwi-channellist-users {
    display: inline-block;
    font-weight: 900;
    text-align: center;
}

@media screen and (max-width: 1024px) {
    .kiwi-channellist-padding-top {
        padding-top: 100px;
    }
}

@media screen and (max-width: 770px) {
    .kiwi-channellist-nav .u-form {
        width: 230px;
    }

    .kiwi-channellist-nav .u-form .u-input {
        width: 100%;
    }
}

