




























































































































































































.kiwi-serverselector-presets {
    margin-bottom: 1em;
}

.kiwi-serverselector-presets label span {
    margin-left: 0;
    transition: opacity 0.2s, width 0.2s;
}

.kiwi-serverselector--custom .kiwi-serverselector-presets label span {
    max-width: 0;
    opacity: 0;
}
