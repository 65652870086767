
















































































































































































































































































.kiwi-header {
    padding: 0;
    transition: all 0.3s;
    line-height: 10px;
    height: 44px;
    box-sizing: border-box;
    text-align: center;
    border-bottom: 1px solid;
    display: flex;
}

.kiwi-header-name .kiwi-header-awaystatus {
    display: inline-block;
    margin-bottom: 2px;
}

.kiwi-header--showall {
    height: auto;
    max-height: 100%;
    overflow-y: auto;
}

/* why this hover? */
.kiwi-header:hover {
    max-height: none;
}

.kiwi-header-name-container {
    font-weight: bold;
    cursor: default;
    margin: 0;
    margin-right: 0.5em;
    opacity: 1;
    font-size: 20px;
    line-height: 43px;
    flex-grow: 1;
    text-align: left;
    overflow-x: hidden;
    white-space: nowrap;
}

.kiwi-header-name {
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 10px;
}

.kiwi-header-name:hover {
    position: absolute;
    padding-right: 10px;
    z-index: 1;
}

.kiwi-header-options {
    width: auto;
    display: inline-block;
    flex-shrink: 0;
}

.kiwi-header-option {
    border: none;
    float: left;
    background: none;
    font-size: 0.8em;
    opacity: 0.9;
    font-weight: 900;
}

.kiwi-header-option a {
    float: left;
    padding: 0 15px;
    line-height: 45px;
    display: block;
    font-weight: 600;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.3s;
}

.kiwi-header-option a:hover {
    opacity: 1;
}

.kiwi-header-option i {
    font-size: 1.2em;
    float: left;
    line-height: 45px;
}

.kiwi-header-options i + span {
    margin-left: 10px;
}

.kiwi-header-option--active {
    opacity: 1;
}

.kiwi-header-option--active a {
    opacity: 1;
}

.kiwi-header-option-leave {
    opacity: 1;
    margin: 0;
    transition: all 0.3s;
}

.kiwi-header-option-leave i {
    margin: 0;
}

/* The not joined button */
.kiwi-header-notjoined {
    border-radius: 0;
    display: inline-block;
    margin: 0 auto;
    float: right;
}

.kiwi-header-notjoined .u-link {
    font-weight: 600;

    /* .kiwi-header height -1px */
    line-height: 43px;
    padding: 0 25px;
    border-radius: 0;
    transition: all 0.3;
}

.kiwi-header-server-settings {
    display: inline;
}

.kiwi-header-server-connection {
    float: right;
    padding-right: 10px;
    line-height: 46px;
}

.kiwi-header-server-connection .u-button {
    float: right;
    line-height: 35px;
    padding: 0 1em;
    margin: 4px 0;
    border-radius: 4px;
}

.kiwi-header-options .u-button {
    text-transform: uppercase;
    font-size: 0.7em;
    font-weight: 600;
    letter-spacing: 0.2em;
    padding: 0.5em 1.7em;
    line-height: 2em;
    border-radius: 0.4em;
}

.kiwi-header-join-channel-button {
    border-radius: 0.3em;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    line-height: inherit;
    height: auto;
    display: inline-block;
    padding: 0.2em 1em;
    font-size: 0.8em;
}

.kiwi-header-close-buffersettings {
    float: right;
}

.kiwi-header-buffersettings {
    padding: 5px;
    margin-top: 1em;
}

.kiwi-header-prompt {
    position: absolute;
    right: 0;
    top: 46px;

    /* z-index 1 higher than the sidebar */
    z-index: 11;
}

.kiwi-header-prompttrans-enter,
.kiwi-header-prompttrans-leave-to {
    top: -45px;
}

.kiwi-header-prompttrans-enter-to,
.kiwi-header-prompttrans-leave {
    top: 46px;
}

.kiwi-header-prompttrans-enter-active,
.kiwi-header-prompttrans-leave-active {
    transition: top 0.2s;
}

@media screen and (max-width: 769px) {
    .kiwi-container-toggledraw-statebrowser {
        border-bottom: none;
    }

    .kiwi-container .kiwi-header {
        margin-right: 0;
        overflow: visible;
        max-height: none;
        padding-left: 0;
        margin-left: 0;
    }

    .kiwi-header-name-container {
        padding-left: 60px;
    }

    .kiwi-header-name {
        padding: 0;
    }

    .kiwi-header-option span {
        display: none;
    }

    .kiwi-header-server-connection .u-button {
        line-height: 32px;
        margin: 7px 0 0 0;
        opacity: 1;
        font-weight: 600;
    }

    .kiwi-header-notjoined {
        height: 45px;
        margin: 0;
        white-space: nowrap;
    }

    .kiwi-header-notjoined .kiwi-header-join-channel-button {
        padding-left: 10px;
        padding-right: 10px;
    }

    .kiwi-containerheader-hidetext {
        display: none;
    }
}

